import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { device } from "../styles/breakPoints"
import Layout from "../components/Layout"

const AboutSection = styled.section`
    display: flex;
    margin-top: 30px;
    flex-direction: column;
    max-width: 1020px;
    padding: 0 70px;
    margin: 0 auto;
    @media ${device.mobile} {
      padding: 25px;
    }
`

const AboutTitle = styled.h1`
    font-size: 3rem;
    display: inline-block;
    span {
        z-index: 101;
        position: relative;
    }
    &::after {
        content: "";
        height: 1.2rem;
        background-color: #f0f0ff;
        display: block;
        position: relative;
        z-index: 100;
        top: -1.2rem;
    }    
`

const AboutContentWrapper = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 3rem;
    @media ${device.mobile} {
      flex-direction: column;
    }
`

const AboutBody = styled.main`
    width: 75%;
    padding: 0 20px;
    @media ${device.mobile} {
      order: 2;
      margin-top: 3rem;
    }
`

const AboutSide = styled.aside`
    width: 25%;
    text-align: center;
    @media ${device.mobile} {
      order: 1;
      margin: 0 auto;
    }
`
const AboutSideProfileImage = styled.div`
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    padding-top: 100%;
    display: block;
    margin: 1rem 0 2rem;
    border-radius: 100%;
`

const AboutPage = ({ data }) => {
    return (
        <Layout>
            <AboutSection>
                <AboutTitle>
                    <span>About</span>
                </AboutTitle>
                <AboutContentWrapper>
                    <AboutBody dangerouslySetInnerHTML={{ __html: data.about.edges[0].node.html }} />
                    <AboutSide>
                        <AboutSideProfileImage style={{backgroundImage: `url(${data.about.edges[0].node.frontmatter.profileImage}`}} />
                        <h3>Tenzin Noryang</h3>
                    </AboutSide>
                </AboutContentWrapper>
            </AboutSection>
        </Layout>
    )
}

export default AboutPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    siteSearchIndex {
        index
    }
    about: allMarkdownRemark(filter: {  frontmatter: { templateKey: { eq: "about-page" }}}) {
      edges {
        node {
          html
          frontmatter {
            profileImage
          }
        }
      }
    }
  }
`